@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.lineAfter::after {
  display: inline-block;
  content: "";
  padding-left: 1rem;
  position: relative;
  background: #ff4c29;
  vertical-align: middle;
  height: 0.2rem;
  width: 6vw;
  left: 0.5rem;
}

.lineBefore::before {
  display: inline-block;
  content: "";
  padding-left: 1rem;
  position: relative;
  background: #ff4c29;
  vertical-align: middle;
  height: 0.2rem;
  width: 6vw;
  right: 0.5rem;
}

/* Skill Cards */

.skillCard:nth-of-type(4n-3) {
  border-top-left-radius: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 0px;
}

.skillCard:nth-of-type(4n-2) {
  border-top-left-radius: 0px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 40px;
}

.skillCard:nth-of-type(4n-1) {
  border-top-left-radius: 0px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 40px;
}

.skillCard:nth-of-type(4n) {
  border-top-left-radius: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 0px;
}
